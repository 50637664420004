.Script-Name {
    font-size: large;
}

.Loader {
    color: #61dafb;
    font-size: 24px;
    margin-right: 8px;
}

.Helper {
    color: #fafafa;
    font-size: 20px;
    margin-left: 8px;
}